document.addEventListener('DOMContentLoaded', function() {
    // Check scroll position on page load
    checkScrollPosition();

    // Add event listener for scroll
    window.addEventListener('scroll', function() {
      checkScrollPosition();
    });
  });

  function checkScrollPosition() {
    var targetElement = document.getElementById('main-logo');
    var scrollPosition = window.scrollY;

    // Adjust the scroll threshold as needed
    var scrollThreshold = 200;

    // Check if the scroll position is beyond the threshold
    if (scrollPosition > scrollThreshold) {
      // Add a class to the target element
      targetElement.classList.add('scrolled');
    } else {
      // Remove the class if the scroll position is less than the threshold
      targetElement.classList.remove('scrolled');
    }
}

$('.offers-slick').slick({
    dots: true,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 3,
    responsive: [
    {
        breakpoint: 800,
        settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
        }
    },
    {
        breakpoint: 640,
        settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
        }
    }
    ]
});

$('.socials-slick').slick({
    dots: true,
    centerPadding: '60px',
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    responsive: [
    {
        breakpoint: 800,
        settings: {
        arrows: false,
        centerPadding: '40px',
        slidesToShow: 1
        }
    },
    {
        breakpoint: 640,
        settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
        }
    }
    ]
});
