$(document).foundation();

$(document).ready(function() {
	// utils
	initVerticalCenter();
	inlineSVGs('.footer__social img');

	// global
	initSliders();
	initOffCanvas();
	initBigText();
	initVideoPopups();
	initAccordions();
	initForms();
	initBookingBar();
	triggerReveal();

	// template specific
	initGallery();
	initFloorplans();
	// initOutAboutFilter()
	initOffers();
	initTeamPopups();
	initOutAndAbout();
	scaleLanding();
	initTreatments();
	initDiningLanding();
	initCountdown();
	initPopup();
	initPopupNewsletter();
});

function triggerReveal() {
	if ($('#popup').length > 0) {
		setTimeout(function() {
			$('#popup').foundation('open');
		},1000)
	}
}


if ($('.home-slider__inner').length > 0) {
	// Dynamically set the right image and text for the first slide for current time of day
	$('[data-morning-image]').each( function () {
		$(this).css('background-image', 'url("' + $(this).data(timeOfDay() + '-image') + '")');
	});
	$('[data-morning-text]').each( function () {
		$(this).text($(this).data(timeOfDay() + '-text'));
	});

	// Make sure the slider always fills the whole screen
	scaleHomeSlider();
	$(window).on('resize', scaleHomeSlider);
}



function initVerticalCenter() {
	$('.vertical-center').each( function () {
		$(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
	});
}



// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
function inlineSVGs(selectorString) {
	$(selectorString).each(function(){
		var $img = $(this);
		var imgID = $img.attr('id');
		var imgClass = $img.attr('class');
		var imgURL = $img.attr('src');

		$.get(imgURL, function(data) {
			// Get the SVG tag, ignore the rest
			var $svg = $(data).find('svg');

			// Add replaced image's ID to the new SVG
			if(typeof imgID !== 'undefined') {
				$svg = $svg.attr('id', imgID);
			}
			// Add replaced image's classes to the new SVG
			if(typeof imgClass !== 'undefined') {
				$svg = $svg.attr('class', imgClass+' replaced-svg');
			}

			// Remove any invalid XML tags as per http://validator.w3.org
			$svg = $svg.removeAttr('xmlns:a');

			// Check if the viewport is set, if the viewport is not set the SVG wont't scale.
			if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
				$svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
			}

			// Replace image with new SVG
			$img.replaceWith($svg);
		}, 'xml');
	});
}



function pad(n, width, z) {
	z = z || '0';
	n = n + '';
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}




function initSliders() {
	$('.home-slider__inner').reformSlider({
		speed: 6000,
		animationSpeed: 1000,
		arrows: true
	});

	if ($('.home-slider__inner').length > 0) {
		// Dynamically set the right image and text for the first slide for current time of day
		$('[data-morning-image]').each( function () {
			$(this).css('background-image', 'url("' + $(this).data(timeOfDay() + '-image') + '")');
		});
		$('[data-morning-text]').each( function () {
			$(this).text($(this).data(timeOfDay() + '-text'));
		});

		// Make sure the slider always fills the whole screen
		scaleHomeSlider();
		$(window).on('resize', scaleHomeSlider);
	}


	$('.landing-grid__slider-inner').reformSlider({
		arrows: true,
		autoplay: false
	});

	$('.language-grid__slider-inner').reformSlider({
		arrows: true,
		autoplay: false
	});



	$('.social-carousel__inner').slick({
		slidesToShow: 3,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.dining-social-carousel__inner').slick({
		slidesToShow: 5,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});


	$('.slider').slick({
		speed: 1000,
		arrows: true,
		dots: true,
		autoplay: true,
		autoplaySpeed: 3000,
		fade: true
	});


	$('.meeting-room-detail__slider-inner').slick({
		arrows: true,
		fade: true
	});
}


function scaleHomeSlider() {
	var topSpace = $('main').css('marginTop').replace('px', '');
	var bottomSpace = $('.footer').outerHeight();
	var sliderHeight = $(window).innerHeight() - (topSpace*1 + bottomSpace*1);

	$('.home-slider__inner').css('height', sliderHeight + 'px');
}


function timeOfDay() {
	var today = new Date();
	var time = 'morning';
	if (today.getHours() >= 12) {
		time = 'afternoon';
	}
	if (today.getHours() >= 18) {
		time = 'evening';
	}
	return time;
}



function initOffCanvas() {
	var mainLinks = $('.off-canvas__nav-sub').siblings()

	$('.off-canvas__nav-sub').each(function () {
		$(this).prepend('<li><a href="' + $(this).siblings().first().attr('href') + '">Overview</a></li>')
	})

	mainLinks.each(function (index) {
		var i = index
		$(this).on('click', function () {

			if ($(this).parent().parent().hasClass('off-canvas__nav-sub')) {
				$(this).parent().addClass('open');
				$(this).next().slideDown();
				return false;
			} else {
				mainLinks.each(function (index) {
					if (i !== index || $(this).parent().parent().hasClass('off-canvas__nav-sub') === true) {
						$(this).parent().removeClass('open').find('ul.off-canvas__nav-sub').slideUp();
					}
				})
				$(this).parent().addClass('open');
				$(this).next().slideDown();
				return false
			}
			
		})
	})
}





function initBigText() {
	$('.landing-intro__main .bigtext').bigtext({
		minfontsize: 20,
		maxfontsize: 90
	});

	$('.standard-content__sidebar .bigtext').bigtext({
		minfontsize: 20,
		maxfontsize: 90
	});
}





function initVideoPopups() {
	var $modal;
	var modal = '<div id="video-modal" class="gallery-modal gallery-modal--video full reveal" data-reveal><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery-modal__iframe"><iframe frameborder="0" src="{{iframeSrc}}"></iframe></div></div>';

	// Detect video links and embed them in a popup
	$('a[href*="youtu"], a[href*="vimeo.com"]').not('[href*="youtube.com/channel"]').addClass('is-video').on('click', function () {
		$modal = $( modal.replace('{{iframeSrc}}', generateVideoEmbedUrl($(this).attr('href'))) );

		$('body').append($modal);

		$('#video-modal').foundation();
        $modal.foundation('open');

        $modal.on('closed.zf.reveal', function () {
	        $modal.remove();
        });

		return false;
	});
}


function generateVideoEmbedUrl(videoUrl) {
	if (videoUrl.indexOf('youtube.com/embed') > -1) {
		// already a YouTube embed code, return same link
		return videoUrl;
	} else if (videoUrl.indexOf('youtu') > -1) {
		// must be either a full or shortened YouTube url so parse and create embed link
	    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
	    var match = videoUrl.match(regExp);

	    if (match && match[2].length == 11) {
	        return 'https://www.youtube.com/embed/' + match[2] + '?rel=0&autoplay=1&modestbranding=1';
	    } else {
	        return videoUrl;
	    }
	} else if (videoUrl.indexOf('player.vimeo.com') > -1) {
		// already a Vimeo embed code, return same link
		return videoUrl;
	} else if (videoUrl.indexOf('https://vimeo.com/') > -1) {
		// normal Vimeo link, so generate and embed code
		return 'https://player.vimeo.com/video/' + videoUrl.split('/')[3] + '?autoplay=true&byline=false&portrait=false&title=false';
	}

	// if all else fails, return the original link
	return embedUrl;
}



function initAccordions() {
	$('.standard-content__cta-why-book, .accordion, .booking-bar__direct').accordiom({
		showFirstItem: false
	});
}



function initForms() {
	$('.contact__form form').validate();
	$('#newsletter-signup').validate();
}



function initBookingBar() {
	$('.header__book-cta').on('click', function () {
		// Add class 'open' to the booking bar
		$('.booking-bar').addClass('open');
	
		// Lazy load the iframe sources
		$('.booking-bar iframe').each(function () {
			if (typeof $(this).data('src') !== 'undefined') {
				$(this).attr('src', $(this).data('src'));
			}
		});
	
		// Check if the clicked element has the class 'table'
		if ($(this).hasClass('table')) {
			$('.header__book-cta, .booking-bar__nav a[href="#table"]').trigger('click');
		}
	});

	$('.booking-bar__close').on('click', function () {
		$('.booking-bar').removeClass('open');
	});

	$('.custom-select').customSelect();

	var nowTemp = new Date();
	var tomorrow = new Date();
		tomorrow.setDate(nowTemp.getDate() + 1);
	var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);
	var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	var checkin = $('#check-in').fdatepicker({
		format: 'dd',
		initialDate: nowTemp,
		onRender: function (date) {
			return date.valueOf() < now.valueOf() ? 'disabled' : '';
		}
	}).on('changeDate', function (ev) {
		if (ev.date.valueOf() > checkout.date.valueOf()) {
			var newDate = new Date(ev.date)
			newDate.setDate(newDate.getDate() + 1);
			checkout.update(newDate);
		}
		updateDates();
		checkin.hide();
		$('#check-out')[0].focus();
	}).data('datepicker');
	var checkout = $('#check-out').fdatepicker({
		format: 'dd',
		initialDate: tomorrow,
		onRender: function (date) {
			return date.valueOf() <= checkin.date.valueOf() ? 'disabled' : '';
		}
	}).on('changeDate', function (ev) {
		updateDates();
		checkout.hide();
	}).data('datepicker');

	updateDates();

	function updateDates() {
		$('input[name="arrive"]').val( checkin.date.getFullYear() + '-' + pad((checkin.date.getMonth()*1 + 1*1), 2) + '-' + checkin.date.getDate() );
		$('input[name="Depart"]').val( checkout.date.getFullYear() + '-' + pad((checkout.date.getMonth()*1 + 1*1), 2) + '-' + checkout.date.getDate() );

		$('#check-in-month').text( months[checkin.date.getMonth()] + ', ' + checkin.date.getFullYear() );
		$('#check-in-day').text( days[checkin.date.getDay()] );

		$('#check-out-month').text( months[checkout.date.getMonth()] + ', ' + checkout.date.getFullYear() );
		$('#check-out-day').text( days[checkout.date.getDay()] );
	}



	$('.booking-bar__nav a').on('click', function () {
		if ($(this).attr('href').indexOf('http') > -1) {
			return; // pass through for the vouchers link
		}

		$('.booking-bar__nav a').removeClass('current');
		$(this).addClass('current');

		$('.booking-bar__option').hide();
		$('.booking-bar__' + $(this).attr('href').replace('#', '')).show();

		$('.booking-bar__table iframe').attr('src', $('.booking-bar__table iframe').attr('src')) // refresh the table booking widget

		return false;
	});


	$('a[href="#book-spa"]').on('click', function () {
		$('.header__book-cta, .booking-bar__nav a[href="#spa"]').trigger('click');
		return false;
	});

	$('a[href="#book-spa-package"]').on('click', function () {
		$('.header__book-cta, .booking-bar__nav a[href="#spa-package"]').trigger('click');
		return false;
	});

	$('a[href="#book-table"]').on('click', function () {
		$('.header__book-cta, .booking-bar__nav a[href="#table"]').trigger('click');
		return false;
	});

	$('a[href="#book-bar"]').on('click', function () {
		$('.header__book-cta, .booking-bar__nav a[href="#bar"]').trigger('click');
		return false;
	});
}





function initGallery() {
	$('.gallery__carousel').slick({
		arrows: true,
		infinite: true,
		slidesToShow: 1,
		centerMode: true,
		variableWidth: true
	});

	$('button.gallery__magnify').on('click', function () {
		// Dynamically load the gallery slider into a reveal popup

		var galleryImageIndex = $(this).data('gallery-index') || 0;

		var $modal = $('<div id="gallery-modal" class="gallery-modal full reveal loading" data-reveal><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery-modal__slider"></div></div>');

        $('body').append($modal);

        $modal.find('.gallery-modal__slider').load($(this).parents('.gallery__carousel').data('gallery-slider'), function () {
	        $modal.removeClass('loading').find('.gallery-modal__slider').slick({
		        arrows: true,
		        fade: true
	        }).slick('slickGoTo', galleryImageIndex, false);
        });

        $('#gallery-modal').foundation();
        $modal.foundation('open');

        $modal.on('closed.zf.reveal', function () {
	        $modal.remove();
        });
	});
}





function initFloorplans() {
	$('.meeting-room-detail__floorplans a').on('click', function () {
		// Dynamically load the floorplan into a reveal popup

		var galleryImageIndex = $(this).data('gallery-index') || 0;

		var floorplanLink = $(this).attr('href');

		var $modal = $('<div id="gallery-modal" class="gallery-modal full reveal loading" data-reveal><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery-modal__slider"></div></div>');

        $('body').append($modal);

        $modal.find('.gallery-modal__slider').load(floorplanLink, function () {
	        $modal.removeClass('loading').find('.gallery-modal__slider').slick({
		        arrows: true,
		        fade: true
	        }).slick('slickGoTo', galleryImageIndex, false);
        });

        $('#gallery-modal').foundation();
        $modal.foundation('open');

        $modal.on('closed.zf.reveal', function () {
	        $modal.remove();
        });

        return false;
	});
}





function initOffers() {
	var $_filterButtons = $('.filters__inner button');
	var $_filterItems = $('.offer, .featured-item, .nonfeatured-item');

	

    $_filterButtons.on('click', function() {

	    if ($(this).hasClass('on')) {
		    $(this).removeClass('on');
	    } else {
		    $('.filters__inner button.on').removeClass('on');
		    $(this).addClass('on');
	    }

        var currentCats = '';

        // Loop through the "on" buttons and build a class selector string of the active
        $_filterButtons.each(function() {
            if ($(this).hasClass('on')) {
                currentCats += '.' + $(this).data('category') + ', ';
            }
        });
        currentCats = currentCats.substr(0, currentCats.length - 2); // Remove the last comma and space from the string, otherwise jQuery borks

		 // No categories selected so show them all
		 if (currentCats == '') {

			$_filterItems.fadeIn();

			return;
		}

        // Hide or show the elements that match the class in Offer module
		function toggleOfferItems () {

			

			$_filterItems.each(function() {

				if ($(this).is(currentCats)) {
					$(this).fadeIn();
					
				} else {
					$(this).fadeOut();
				}
			});
		}

		// Hide or show the elements that match the class in Out-About module

		function toggleOutAboutItems () {

			// Sorting Featured Items to the front of the array

			var sortedArray = $_filterItems.sort(function(a, b) {

				return $(a).attr('class').localeCompare($(b).attr('class'));
			});

		
	
	
			sortedArray.each(function() {
	
				if (currentCats.includes($(this).data('category'))) {
					$('.out-about').append($(this))
					$(this).fadeIn();
					
				} else if (currentCats === '.all') {
					$('.out-about').append($(this))
					$(this).fadeIn();
					
				} else {
					$(this).hide();
				}
			});

		}
       

		$('body').hasClass('out-about-page') ? toggleOutAboutItems() : toggleOfferItems(); 

		
    });

    // Pre-filter the offers
    // Get the url parameters
    var urlParams = {};
    var e,
        a = /\+/g, // Regex for replacing addition symbol with a space
        r = /([^&=]+)=?([^&]*)/g,
        d = function(s) {
            return decodeURIComponent(s.replace(a, ' '));
        },
        q = window.location.search.substring(1);

    while ((e = r.exec(q))) urlParams[d(e[1])] = d(e[2]);

    if (urlParams['category']) {
        $('.filters__inner button[data-category="' + urlParams['category'] + '"]').trigger('click');
    }
}


function initTeamPopups() {
	$('.team-grid__item a, .dining-team-grid__item a, .nonfeatured-item a').on('click', function () {
		loadTeamPopup($(this).attr('href'));
		return false;
	});

	$('.team-popup__next').on('click', function () {
		var popupNumber = $(this).parents('.team-popup').attr('id').replace('team-popup-', '');

		if (popupNumber == $('.team-grid__item').length) {
			loadTeamPopup($('#team-popup-1'));
		} else {
			loadTeamPopup($('#team-popup-' + (popupNumber*1 + 1*1)));
		}
	});

	$('.team-popup__prev').on('click', function () {
		var popupNumber = $(this).parents('.team-popup').attr('id').replace('team-popup-', '');

		if (popupNumber == 1) {
			loadTeamPopup($('#team-popup-' + $('.team-grid__item').length));
		} else {
			loadTeamPopup($('#team-popup-' + (popupNumber - 1)));
		}
	});

	$('.dining-team-popup__next').on('click', function () {
		var popupNumber = $(this).parents('.dining-team-popup').attr('id').replace('dining-team-popup-', '');

		if (popupNumber == $('.dining-team-grid__item').length) {
			loadTeamPopup($('#dining-team-popup-1'));
		} else {
			loadTeamPopup($('#dining-team-popup-' + (popupNumber*1 + 1*1)));
		}
	});

	$('.dining-team-popup__prev').on('click', function () {
		var popupNumber = $(this).parents('.dining-team-popup').attr('id').replace('dining-team-popup-', '');

		if (popupNumber == 1) {
			loadTeamPopup($('#dining-team-popup-' + $('.dining-team-grid__item').length));
		} else {
			loadTeamPopup($('#dining-team-popup-' + (popupNumber - 1)));
		}
	});
}






function loadTeamPopup($popupSelector) {
	$($popupSelector).find('.team-popup__image, .dining-team-popup__image').css('background-image', 'url(' + $($popupSelector).find('.team-popup__image, .dining-team-popup__image').data('image') + ')'); // lazily load the large image for the popup
	$($popupSelector).on('open.zf.reveal', function () {
		setTimeout(function () {
			$(window).trigger('resize');
		}, 100);
	});
	$($popupSelector).foundation('open');

}


function initOutAndAbout() {
	var nextElBtn = $('.revealed-item__inner__next');
	var prevElBtn = $('.revealed-item__inner__prev');
	var nonFeaturedItems = Array.from($('.nonfeatured-item'));
	var fltrBtnAll = $('.filters__inner button');

	function handleFilter(elements, cat) {
		var filteredItems = elements.filter(function (element) {
			return element.dataset.category === cat
		}) 
		return filteredItems
	}

	prevElBtn.first().hide();
	nextElBtn.last().hide();

	fltrBtnAll.on('click', function () {
		if ($(this).data('category') !== 'all') {	
			prevElBtn.hide()
			nextElBtn.hide()
			var filtered = handleFilter(nonFeaturedItems, $(this).data('category'))
			
		} else {
			prevElBtn.show()
			nextElBtn.show()
		}
	})

	


	nextElBtn.on('click', function () {
		var getitemIdNum = $(this).parents('.revealed-item').attr('id').split('-');
		var itemIdNum = Number(getitemIdNum[1])
		var nextId = itemIdNum + 1;

		if (nextId === nonFeaturedItems.length) {
			return
		}
		if (itemIdNum ===nonFeaturedItems.length- 2) {
			$('#reveal-' + nextId + ' .revealed-item__inner .revealed-item__inner__next').hide()
		}
		$('#reveal-' + nextId).foundation('open');
	});

	prevElBtn.on('click', function () {
		var getitemIdNum = $(this).parents('.revealed-item').attr('id').split('-');
		var itemIdNum = Number(getitemIdNum[1])
		var prevId = itemIdNum -1;
	
		if (prevId === -1) {
			return
		}

		if (itemIdNum === 1) {
			$('#reveal-' + prevId + ' .revealed-item__inner .revealed-item__inner__prev').hide()
		}
		$('#reveal-' + prevId).foundation('open');
	});

	

}


// Always make sure the landing page fills the whole browser.
function scaleLanding() {
	if ( $('.landing-intro__list').length > 0 ) {
		applyLandingHeight();
		$(window).on('resize', applyLandingHeight);
	}
}

function applyLandingHeight() {
	if ( $(window).innerWidth() <= 800 ) {
		// quit if on mobile
		$('.landing-intro').css('height', 'auto');
		return;
	}

	// loop through all of the content items to get a total height
	var totalHeight = 0;
	$('.landing-intro__list .vertical-center-cell > *').each( function () {
		totalHeight += parseInt( $(this).outerHeight() );
	});

	totalHeight += 500; // add a bit of a margin

	var topSpace = $('main').css('marginTop').replace('px', '');
	var bottomSpace = $('.footer').outerHeight();
	var sliderHeight = $(window).innerHeight() - (topSpace*1 + bottomSpace*1);

	// Only apply the new height if it is more than the height of the content
	if ( totalHeight > sliderHeight ) {
		$('.landing-intro').css('height', 'auto');
	} else {
		$('.landing-intro').css('height', sliderHeight + 'px');
	}
}



function initTreatments() {
	$('.treatments__anchors a[href^="#"]').click(function () {
	    $('html, body').animate({
	        scrollTop: $('[name="' + $.attr(this, 'href').substr(1) + '"]').offset().top - 130
	    }, 500);

	    return false;
	});
}



function initDiningLanding() {
	$('.dining-landing-intro__scroll button').on('click', function () {
		$([document.documentElement, document.body]).animate({
			scrollTop: $('.dining-landing-grid').first().offset().top - 75
		}, 1000);
	});

	$('.dining-landing-events').each(function () {
		$(this).prev('.dining-landing-grid').addClass('no-stroke');
	});
}

function initCountdown() {
	// if ( $('.slider__countdown').length > 0 ) {

		$('.popup__container-counter').countdown( $('.popup__container-counter').data('countdown-date'), function (event) {
			$(this).html(
				'<div class="cell small-4">' + event.strftime('%D') + '<br /><small>Days</small></div>' +
				'<div class="cell small-4">' + event.strftime('%H') + '<br /><small>Hours</small></div>' +
				'<div class="cell small-4">' + event.strftime('%M') + '<br /><small>Minutes</small></div>'
			);
	    });
	// }
}


function initPopup() {
    if ($('#popup-countdown').length > 0) {
        var popupDelay = $('#popup-countdown').data('popup-delay') || 0;
        var popupKeepHiddenFor = $('#popup-countdown').data('popup-keep-hidden-for-n-days') || 0;

        if (!Cookies.get('hasSeenPopup')) {
            setTimeout(function() {
                $('#popup-countdown').foundation('open');

                if (popupKeepHiddenFor > 0) {
                    Cookies.set('hasSeenPopup', 'true', { expires: popupKeepHiddenFor });
                }
            }, popupDelay);
        }
    }
}

function initPopupNewsletter() {
	if ($('#popup-newsletter').length > 0) {
			var popupDelay = $('#popup-newsletter').data('popup-delay') || 0;
			var popupKeepHiddenFor = $('#popup-newsletter').data('popup-keep-hidden-for-n-days') || 0;

			if (!Cookies.get('hasSeenPopup')) {
					setTimeout(function() {
							$('#popup-newsletter').foundation('open');

							if (popupKeepHiddenFor > 0) {
									Cookies.set('hasSeenPopup', 'true', { expires: popupKeepHiddenFor });
							}
					}, popupDelay);
			}
	}
}

